body {
  margin: 0;
}
p {
  line-height: 1 !important;
  color: #455560 !important;
}
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #f97d24;
}

a:hover {
  color: #f8ae8f !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
iframe {
  display: none !important;
}
/* TODO */
/* Adicionar aos temas dark and light */
.ant-menu-hidden,
.ant-menu-submenu-hidden {
  display: none;
}

.app-content {
  min-height: calc(100vh - 100px) !important;
}
.app-content.layout-top-nav {
  min-height: calc(100vh - 100px - 55px) !important;
}
.ant-layout-content > .ant-card {
  margin-bottom: 0 !important;
}
.ant-form-item {
  margin-bottom: 10px !important;
}
.ant-menu-sub > .ant-menu-item {
  margin-bottom: 4px !important;
}

.ant-col > .ant-card > .ant-card-body {
  height: 100%;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0px calc(50% - 12px) !important;
}
.ant-btn-primary:focus,
.ant-btn-primary:hover {
  background: #f97d24 !important;
  border-color: #f97d24 !important;
}
.circle-picker {
  width: 100% !important;
  justify-content: center;
}
.circle-picker > span > div {
  margin: 2px 0.065rem !important;
}

.infoBox {
  width: 150px;
  overflow: unset !important;
  background-color: white;
  border-radius: 5px;
  padding: 5px 10px;
  transform: translateY(-50%) !important;
}

.ant-input-disabled,
.ant-input[disabled] {
  color: #929292 !important;
  cursor: default !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.gm-style-iw-t {
  top: -45px;
}

button.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
  margin: 0px 0px 16px !important;
}
.ant-drawer-header {
  border: none !important;
}

.ant-drawer-body {
  padding-top: 5px !important;
}

small {
  color: rgba(0, 0, 0, 0.7);
  font-size: smaller !important;
  font-weight: 400;
}
.ant-modal {
  padding: 0 !important;
}

.ant-pagination-item-active {
  background: transparent !important;
}

.ant-modal-footer {
  border: none !important;
}

.ant-pagination-item-active > a {
  color: #fd4e06 !important;
}

.ant-input-group-addon > button,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper {
  box-shadow: none !important;
  border-color: #dadada !important;
}

.ant-result-content {
  padding: 0 !important;
  background-color: #ffffff !important;
}
.ant-drawer-content-wrapper {
  max-width: 100% !important;
}
.nav-profile {
  width: 18.5rem !important;
  max-width: 100% !important;
}

.ant-tag {
  border-radius: 100px !important;
}

.gm-fullscreen-control,
.gm-bundled-control > div > div {
  border-radius: 8px !important;
}

.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-btn:hover,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-remove:hover,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #f38d63 !important;
}

.ant-radio-button-wrapper-checked:not(
    [class*=' ant-radio-button-wrapper-disabled']
  ).ant-radio-button-wrapper:first-child {
  border-color: rgb(253, 78, 6) !important;
}

.ant-radio-button-wrapper:hover {
  border-color: #f38d63 !important;
  color: #f38d63 !important;
}

.app-header .nav-right {
  margin: unset !important;
}

nav.ant-breadcrumb ol {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

li:last-child > .ant-breadcrumb-separator {
  display: none;
}
.ant-form-vertical .ant-form-item-row {
  flex-direction: column;
}
.ant-avatar.ant-avatar-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ant-tabs-tab {
  margin: 0 !important;
}

.custom-slider-container .ant-slider-mark-text {
  font-size: 12px;
}
.row-animal-map > .ant-col {
  margin-bottom: 16px;
  margin-top: 16px;
}

.ant-modal-body > .display-block-item > .ant-row {
  display: block !important;
}

.ant-pagination {
  display: inline-flex;
  align-items: center;
}

ul.ant-table-pagination > li:not(:last-child):not(:first-child) {
  margin: auto 0 !important;
}

.ant-pagination-total-text {
  margin-right: 30px !important;
  align-content: center;
}

.ant-pagination-options {
  margin-left: 30px !important;
  align-content: center;
}

.none-margin {
  margin: 0px !important;
}

.margin-left {
  margin-left: 10px;
}

.gm-style-iw-d {
  padding: 0 12px 12px 0 !important;
  overflow: auto !important;
}
